




import GeneralRulesCC from '/src/class-components/settings/GeneralRulesCC.vue';
import { Vue, Component } from 'vue-property-decorator';

@Component({
  components: {
    GeneralRulesCC
  }
})
export default class GeneralRules extends Vue {}
